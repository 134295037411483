import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const HEIGHT_OF_SHREDS = 8;
const DEFAULT_STEP = 2;
const DEFAULT_MAX_SIZE = 100;

/**
 * @param {number} step
 * @param {number} maxSize
 * @returns {string}
 * @memberof DcrTornPaper
 * @description Returns list of steps for the given max size based on step size with random values to be used in CSS to resemble torn paper
 * @example
 * getPaperShredSteps(2, 100); // 0% 0%, 2% 66%, 4% 100%, ..., 98% 14%, 100% 0%
 */
const getPaperShredSteps = (stepSize = DEFAULT_STEP, maxSize = DEFAULT_MAX_SIZE) => {
    //returns an array of matched numbers from 0 to 100 i.e. 0% 34%, 2% 66%, 4% 100%, ...etc
    let steps = [];
    for (let i = 0; i <= 100; i += stepSize) {
        steps.push(`${i}% ${(i >= maxSize || i <= 0) ? 0 : Math.round(Math.random() * maxSize)}%`);
    }

    return steps.join(',');
};

const useStyles = makeStyles({
    paperContainer: {
        position: 'relative',
        width: props => props.width,
        margin: props => props.margin,
        maxWidth: props => props.maxWidth,
    },
    paper: {
        height: 'auto',
        overflow: 'hidden',
        padding: props => props.padding,
        background: props => props.backgroundLight,
        boxShadow: '0px 8px 5px 3px rgb(0 0 0 / 5%)',
        '@media (prefers-color-scheme: dark)': {
            background: props => props.backgroundDark
        },
        '&::before, &::after': {
            content: '""',
            height: props => props.heightOfShreds + 1, //Using 9 instead of 8 as Chrome has a little math problem
            position: 'absolute',
            left: 0,
            right: 0
        },
        '&::before': {
            backgroundColor: props => props.backgroundLight,
            top: props => -1 * props.heightOfShreds,
            transform: 'rotate(180deg)',
            clipPath: props => `polygon(${props.topPaperPath})`,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: props => props.backgroundDark
            }
        },
        '&::after': {
            backgroundColor: props => props.backgroundLight,
            bottom: props => -1 * props.heightOfShreds,
            clipPath: props => `polygon(${props.bottomPaperPath})`,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: props => props.backgroundDark
            }
        }
    }
});

const DcrTornPaper = ({children, ...props}) =>{
    let topPaperPath = getPaperShredSteps();
    let bottomPaperPath = getPaperShredSteps();

    const classes = useStyles({
        heightOfShreds: HEIGHT_OF_SHREDS,
        backgroundLight: '#fbfbf8',
        backgroundDark: '#191919',
        width: 700,
        maxWidth: 'calc(100% - 20px)',
        margin: '40px auto',
        padding: 20,
        topPaperPath,
        bottomPaperPath,
        ...props
    });

    return (
        <div className={`${classes.paperContainer} ${props.containerClassName || ''}`}>
            <div className={classes.paper}>{children}</div>
        </div>
    );
};

export default DcrTornPaper;
